import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as valuesStyles from "./values-scholarship.module.scss"

const ValuesScholarship = ({ pageContext, location }) => {
  const pageTitle = "2024 Values Scholarship Application"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />

      <div className={valuesStyles.wrapper}>
        {/* Hero Grid */}
        <section className={valuesStyles.heroGrid}>
          <div className={valuesStyles.copyAndImage}>
            {/* HERO IMAGE */}
            <div className={valuesStyles.heroImage}>
              <img
                src={
                  require("../../../assets/images/about-us/values/passion-logo.png")
                    .default
                }
                alt=""
              />
            </div>
            {/* LOGO AND ANCHOR */}
            <div className={valuesStyles.copy}>
              <p data-typesense-field="description">At Rocky Brands we are passionate about our brands, customners, partners, and the associates that make our company great. We value <span className={valuesStyles.rbBrown}>Integrity, Responsibility, and Humility</span>. Tell us what these values mean to you and how they impact your passion. Include an example of a time or times that you have exhibited the Rocky Brands Values.</p>
              <p data-typesense-field="description">This Scholarship is available to current seniors. Essays must be typed and uploaded with application.</p>
              <p data-typesense-field="description">Application deadline is <span className={valuesStyles.rbBrown}>March 31, 2024</span>.</p>
              <p data-typesense-field="description">The $500 Scholarship will be given to the college/university on your behalf.</p>
              <p data-typesense-field="description">For questions or additional information, please contact Ashley Walter at <a className={valuesStyles.rbBrown} href="tel:+17407539100p2513" title="Call Ashley Walters">(740) 753-9100</a> or <a className={valuesStyles.rbBrown} href="mailto: ashley.walter@rockybrands.com" title="Email Ashley Walters">ashley.walter@rockybrands.com</a></p>

              {/* <div>
                <a
                  href={scholarshipPdf}
                  className={valuesStyles.ctaScholarship}
                  title="Anchor to Download Scholarship Application PDF"
                  download
                >
                  Download Application
                </a>
              </div> */}
            </div>
          </div>

          <div className={valuesStyles.form}>
              {/* values scholarship form */}
              <form
                  name="values-scholarship-application-submission"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="special-request"
                  enctype="multipart/form-data"
                >
                  <input class="hidden" name="special-request" />
                  {/* hidden input for netlify */}
                  <input
                    type="hidden"
                    name="form-name"
                    value="values-scholarship-application-submission"
                  ></input>

                  {/* first name */}
                  <div className="form-group">
                    <label htmlFor="full-name">Full Name<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="full-name"
                      name="full-name"
                      placeholder="Enter First Name"
                      required
                    />
                  </div>

                  {/* high school name */}
                  <div className="form-group">
                    <label htmlFor="school-name">Name of High School<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="school-name"
                      name="school-name"
                      placeholder="Enter School Name"
                      required
                    />
                  </div>

                  {/* GPA */}
                  <div className="form-group">
                    <label htmlFor="gpa">Grade Point Average<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="gpa"
                      name="gpa"
                      placeholder="Enter GPA"
                      required
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="form-group">
                    <label htmlFor="phone-number">Phone Number<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone-number"
                      name="phone-number"
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>

                  {/* Email Address */}
                  <div className="form-group">
                    <label htmlFor="email-address">Email Address<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span></label>
                    <input
                      type="text"
                      className="form-control"
                      id="email-address"
                      name="email-address"
                      placeholder="Enter Email Address"
                      required
                    />
                  </div>

                  {/* College or University Name */}
                  <div className="form-group">
                    <label htmlFor="college-name">College or University you plan on attending</label>
                    <input
                      type="text"
                      className="form-control"
                      id="college-name"
                      name="college-name"
                      placeholder="Enter College or University Name"
                    />
                  </div>

                  {/* Why Scholarship */}
                  <div className="form-group">
                    <label htmlFor="why-scholarship">What drove you to look for a scholarship program?</label>
                    <input
                      type="text"
                      className="form-control"
                      id="why-scholarship"
                      name="why-scholarship"
                      placeholder="Enter your answer"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label
                      htmlFor="values-scholarship-application"
                    >
                      Please upload completed essay<span className={valuesStyles.requiredAsterisk}><sup>*</sup></span>
                    </label>
                    <input
                      type="file"
                      className="file-upload"
                      id="values-scholarship-application"
                      name="values-scholarship-application"
                      placeholder="Upload Application"
                      required
                    />

                    <small>
                      Only valid and completed applications will be considered.
                    </small>
                  </div>
                  {/* submit button */}
                  <div>
                    <input className={valuesStyles.ctaScholarship} type="submit" value="Submit" />
                  </div>
                </form>
            </div>
        </section>
        {/* END HERO GRID */}
      </div>
    </Layout>
  )
}

export default ValuesScholarship

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Values Scholarship</title>
    <meta
      name="description"
      content="To provide impactful support to our local communities by awarding grants to build a clean, safe, and healthy community for our neighbors."
    />
  </>
)
